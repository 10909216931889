.group-407-14 {
  align-items: flex-end;
  background: #003aba;
  border-radius: 12px;
  display: flex;
  height: 24px;
  min-width: 24px;
  padding: 7.9px 5.4px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  align-self: flex-end;
  margin-right: 17px;
  position: absolute;
  bottom: 13px;
}

.rotate-arrow-icon {
  transform: rotate(-180deg);
}

.vector-4-10 {
  height: 7px;
  width: 13px;
}

.group-407-14.group-407-15 {
  top: 2579px;
}
