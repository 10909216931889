.overlap-group12-23 {
  height: 316px;
  margin-left: 19px;
  position: relative;
  width: 664px;
}

.rectangle-115-17 {
  background-color: var(--white);
  border-radius: 10px;
  height: 316px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 663px;
}

.tasks-17 {
  left: 29px;
  letter-spacing: 0;
  position: absolute;
  top: 18px;
}

.release-transportation-documents-17 {
  left: 30px;
  letter-spacing: 0;
  position: absolute;
  top: 167px;
}

.review-catch-certificate-xdf1-d3-fa-17 {
  left: 30px;
  letter-spacing: 0;
  position: absolute;
  top: 75px;
}

.vector-33-17 {
  left: 0;
  position: absolute;
  top: 200px;
  width: 664px;
}

.vector-34-17 {
  left: 0;
  position: absolute;
  top: 246px;
  width: 664px;
}

.vector-35-17 {
  left: 0;
  position: absolute;
  top: 108px;
  width: 664px;
}

.vector-36-17 {
  left: 0;
  position: absolute;
  top: 62px;
  width: 664px;
}

.vector-38-17 {
  left: 0;
  position: absolute;
  top: 154px;
  width: 664px;
}

.review-lot-457258-dd-17 {
  left: 30px;
  letter-spacing: 0;
  position: absolute;
  top: 213px;
}

.review-lot-876258-lm-17 {
  left: 30px;
  letter-spacing: 0;
  position: absolute;
  top: 259px;
}

.sales-note-1255-17 {
  left: 30px;
  letter-spacing: 0;
  position: absolute;
  top: 121px;
}

.view-141 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: #003aba;
  background-clip: text;
  left: 593px;
  letter-spacing: 0;
  position: absolute;
  top: 215px;
}

.view-142 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: #003aba;
  background-clip: text;
  left: 593px;
  letter-spacing: 0;
  position: absolute;
  top: 261px;
}

.view-143 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: #003aba;
  background-clip: text;
  left: 593px;
  letter-spacing: 0;
  position: absolute;
  top: 169px;
}

.view-144 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: #003aba;
  background-clip: text;
  left: 593px;
  letter-spacing: 0;
  position: absolute;
  top: 123px;
}

.view-145 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: #003aba;
  background-clip: text;
  left: 593px;
  letter-spacing: 0;
  position: absolute;
  top: 77px;
}

.overlap-group11-17 {
  height: 23px;
  left: 599px;
  position: absolute;
  top: 23px;
  width: 20px;
}

.rectangle-133-17 {
  background-color: var(--x5);
  border-radius: 3.23px;
  height: 20px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 20px;
}

.text-31-1 {
  left: 5px;
  letter-spacing: 0;
  position: absolute;
  top: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
