.logo-area {
  align-items: center;
  display: flex;
  height: 51px;
  left: 88px;
  min-width: 250px;
  position: absolute;
  top: 17px;
}

.group-134 {
  height: 40px;
  margin-top: 1px;
  width: 103px;
}

.vector-3 {
  height: 46px;
  margin-bottom: 1px;
  margin-left: 11px;
  width: 1px;
}

.logo-text {
  margin-left: 11px;
  position: relative;
  width: 169px;
  display: flex;
  align-items: center;
  height: 40px;
}

.sustainability-text {
  left: 0;
  letter-spacing: 0;
  position: absolute;
  top: 21px;
}

.fishing-text {
  left: 1px;
  letter-spacing: 0;
  position: absolute;
  top: 0;
}
